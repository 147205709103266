import React, { useState } from "react";
import InputBox from "./InputBox";
import { BubbleType } from "./RootBubbleView";
import { UserBubble } from "./UserBubble";
import ChatAdModeSelector from "./ChatAdModeSelector";
import { toast } from "sonner";
import { ChatEmptyView } from "./ChatEmptyView";
import { useMutation } from "@tanstack/react-query";
import { useChatMode } from "~/providers/ChatModeProvider";
import AssistantBubble from "./AssistantBubble";
import { FollowUps } from "./FollowUps";
import { Message } from "~/components/models/Message";

export const ChatContent = () => {
  const { adType, isFollowUpsEnabled } = useChatMode();
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);

  const [streamingMessage, setStreamingMessage] = useState<string>("");

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [followUps, setFollowUps] = useState<string[]>([]);

  function didAskQuestion(question: string) {
    setFollowUps([]);
    ask(question);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      message,
      history,
      ad_type,
    }: {
      message: string;
      history: Message[];
      ad_type: string;
    }) => {
      const eventSource = new EventSource(
        `/chat?message=${encodeURIComponent(message)}&ad_type=${encodeURIComponent(
          adType.toLowerCase().replace(" ", "_")
        )}&reset=${messages.length === 1}`
      );

      let accumulatedMessage = "";

      return new Promise((resolve, reject) => {
        eventSource.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.type === "all") {
            accumulatedMessage = data.content;
            setStreamingMessage(accumulatedMessage);
          } else if (data.type === "follow_ups") {
            setFollowUps(data.content);
          } else {
            accumulatedMessage += data.content;
            setStreamingMessage(accumulatedMessage);
          }
        });

        eventSource.addEventListener("error", (event) => {
          if (event.eventPhase === EventSource.CLOSED) {
            eventSource.close();
            console.log("STREAM CLOSED");
            resolve(accumulatedMessage);
          } else {
            console.error("STREAM ERROR: ", event);
            eventSource.close();
            reject(new Error("EventSource failed"));
          }
        });
      });
    },
    onSuccess: (data) => {
      setMessages((prev) => [
        ...prev,
        {
          id: Math.random().toString(),
          role: "assistant",
          content: streamingMessage,
        },
      ]);
      console.log("STREAMING MESSAGE: ", streamingMessage);
      window.koah?.send(message, streamingMessage, adType.toLowerCase());
      setStreamingMessage("");
    },
    onError: (error) => {
      console.log({ error });
      toast.error("Failed to send message. Please try again.");
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    ask(message);
  }

  function ask(message: string) {
    mutate(
      { message, history: messages, ad_type: adType },
      {
        onSuccess: () => {
          setMessage("");
        },
      }
    );
    setMessages([...messages, { id: Math.random().toString(), role: "user", content: message }]);
    setFollowUps([]);
  }

  return (
    <>
      <div className="absolute top-4 left-4">
        <ChatAdModeSelector />
      </div>

      <div className="flex flex-col items-center justify-center w-full h-screen">
        <div className="flex flex-col w-full h-full gap-4 overflow-y-scroll pb-[300px]">
          <div className="flex flex-col gap-4 min-w-[700px] max-w-[700px] mx-auto">
            {messages.map((message, idx) => {
              const bubbleType =
                idx === 0
                  ? BubbleType.FIRST
                  : idx === messages.length - 1 && !streamingMessage
                  ? BubbleType.LAST
                  : null;

              if (message.role === "assistant") {
                return (
                  <AssistantBubble key={message.id} type={bubbleType} text={message.content} />
                );
              } else {
                return <UserBubble key={message.id} type={bubbleType} message={message} />;
              }
            })}
            {streamingMessage && (
              <AssistantBubble
                key={messages.length + 1}
                text={streamingMessage}
                type={BubbleType.LAST}
              />
            )}
            {isFollowUpsEnabled && followUps.length > 0 && (
              <FollowUps
                followUps={followUps}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                didAskQuestion={didAskQuestion}
              />
            )}
          </div>
        </div>

        <InputBox message={message} onChange={setMessage} onSubmit={handleSubmit} />
      </div>

      {messages.length === 0 && <ChatEmptyView />}
    </>
  );
};
