import React from "react";
import { ChevronUp, CornerDownRight } from "lucide-react";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "~/shadcn/components/ui/collapsible";
import { Separator } from "~/shadcn/components/ui/separator";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export const FollowUps = ({
  followUps,
  isExpanded,
  setIsExpanded,
  didAskQuestion,
}: {
  followUps: string[];
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  didAskQuestion: (question: string) => void;
}) => {
  return (
    <Collapsible open={isExpanded} onOpenChange={setIsExpanded}>
      <div className="flex flex-col gap-4 border rounded-lg p-4">
        <CollapsibleTrigger>
          <div className="flex items-center justify-between">
            <div className="font-medium">Ask follow up questions</div>
            <ChevronUp
              className={`w-5 h-5 text-slate-500 transition-transform duration-300 ${
                isExpanded ? "rotate-180" : ""
              }`}
              strokeWidth={2.4}
            />
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          {followUps.map((followUp, idx) => (
            <div
              key={idx}
              className="flex flex-col gap-2 hover:text-blue-600 transition-colors duration-300"
            >
              <button
                className="flex items-center gap-4 text-left"
                onClick={() => {
                  didAskQuestion(followUp.replace("<sub>Ad</sub>", ""));
                }}
              >
                <CornerDownRight className="w-4 h-4" />
                <Markdown rehypePlugins={[rehypeRaw]} className="reactMarkDown">
                  {followUp}
                </Markdown>
              </button>

              {idx < followUps.length - 1 && <Separator className="mb-2" />}
            </div>
          ))}
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
};
