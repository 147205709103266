import React from "react";
import { CheckCircle, ChevronDown } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/components/ui/popover";
import { ChatOption, useChatMode } from "~/providers/ChatModeProvider";
import { cn } from "~/lib/utils";
import { Switch } from "~/shadcn/components/ui/switch";
import { Separator } from "~/shadcn/components/ui/separator";

const AD_TYPES: {
  type: ChatOption;
  description: string;
}[] = [
  {
    type: ChatOption.NO_ADS,
    description: "Pure chat, no ads",
  },
  {
    type: ChatOption.PREFIX,
    description: "Ads prepended to the start of messages",
  },
  {
    type: ChatOption.SUFFIX,
    description: "Ads appended to the end of messages",
  },
  {
    type: ChatOption.INLINE,
    description: "Insert ads within messages",
  },
];

function FollowUpsRow() {
  const { isFollowUpsEnabled, setIsFollowUpsEnabled } = useChatMode();

  return (
    <div className="flex gap-2 items-center justify-between p-2 rounded-lg">
      <span className="font-medium text-sm">Follow Ups</span>
      <Switch
        checked={isFollowUpsEnabled}
        onCheckedChange={() => setIsFollowUpsEnabled(!isFollowUpsEnabled)}
      />
    </div>
  );
}

function AdType({
  adType,
  description,
  setAdType,
  selected,
}: {
  adType: ChatOption;
  description: string;
  setAdType: (adType: ChatOption) => void;
  selected: boolean;
}) {
  return (
    <button
      className="flex gap-2 items-center justify-between hover:bg-slate-100 p-2 rounded-lg"
      onClick={() => setAdType(adType)}
    >
      <div className="flex flex-col gap-1 items-start">
        <span className="font-medium text-sm">{adType}</span>
        <span className="text-muted-foreground text-xs text-left whitespace-normal">
          {description}
        </span>
      </div>
      <CheckCircle
        className={cn(
          "w-4 h-4 text-green-500 transition-opacity duration-200",
          selected ? "opacity-100" : "opacity-0"
        )}
        strokeWidth={2.0}
      />
    </button>
  );
}

export default function ChatAdModeSelector() {
  const { adType, setAdType } = useChatMode();

  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex items-center gap-2 hover:bg-slate-100 p-2 rounded-lg">
          <span className="text-lg font-medium">{adType}</span>
          <ChevronDown className="w-4 h-4 text-slate-400" strokeWidth={3.5} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-80 ml-4 py-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <span className="font-medium px-2 py-2">Modes</span>
            {AD_TYPES.map((type) => (
              <AdType
                key={type.type}
                adType={type.type}
                description={type.description}
                setAdType={setAdType}
                selected={adType === type.type}
              />
            ))}
          </div>
          <Separator />
          <FollowUpsRow />
        </div>
      </PopoverContent>
    </Popover>
  );
}
