import React from "react";
import { cn } from "~/lib/utils";

export enum BubbleType {
  FIRST,
  LAST,
}

export enum Speaker {
  ASSISTANT,
  USER,
}

export default function RootBubble({
  type,
  speaker,
  setIsHovered,
  children,
}: {
  type?: BubbleType;
  speaker: Speaker;
  setIsHovered: (isHovered: boolean) => void;
  children: React.ReactNode;
}) {
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cn(
        "flex",
        type === BubbleType.FIRST && "mt-24",
        type === BubbleType.LAST && "mb-24",
        speaker === Speaker.ASSISTANT && "justify-start",
        speaker === Speaker.USER && "justify-end"
      )}
    >
      {children}
    </div>
  );
}
