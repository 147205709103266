import React from "react";
import RootBubble, { BubbleType, Speaker } from "./RootBubbleView";
import { Message } from "./models/Message";

export function UserBubble({
  type,
  message,
}: {
  type?: BubbleType;
  message: Message;
}) {
  return (
    <RootBubble type={type} speaker={Speaker.USER} setIsHovered={() => {}}>
      <span className="text-right px-4 py-3 bg-[#F2F2F2] rounded-full mb-4">
        {message.content}
      </span>
    </RootBubble>
  );
}
