import React from "react";
import { ArrowRightIcon } from "lucide-react";

export default function InputBox({
  message,
  onChange,
  onSubmit,
}: {
  message: string;
  onChange: (message: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <form
      className="flex flex-col items-center justify-center bottom-0 absolute max-w-2xl bg-white border w-full rounded-t-lg px-2 gap-4"
      onSubmit={onSubmit}
    >
      <div className="flex gap-2 w-full mt-2">
        <input
          placeholder="Ask anything..."
          className="w-full bg-transparent p-2 focus:outline-none text-sm border-none focus:ring-0"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={message}
        />
        <button className="p-2">
          <ArrowRightIcon className="w-4 h-4" />
        </button>
      </div>

      <span className="text-xs text-slate-500 mb-4 text-left w-full ml-4">
        AI can make mistakes. Check important info.
      </span>
    </form>
  );
}
