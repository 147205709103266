import React, { useState } from "react";
import MediumSelector from "./chat/MediumSelector";
import { ChatContent } from "./chat/Chat";
import { Civit } from "./image/Civit";
import { ChatModeProvider } from "../providers/ChatModeProvider";

export const Gallery = () => {
  const [selectedTab, setSelectedTab] = useState<string>("chat");
  console.log("Gallery selected?");

  return (
    <ChatModeProvider>
      <div className="flex flex-col items-center justify-center h-screen bg-[#FDFAFA]">
        <div className="flex flex-col items-center absolute top-4 left-0 right-0">
          <MediumSelector
            selected={selectedTab}
            onChange={(selected) => setSelectedTab(selected)}
          />
        </div>
        {selectedTab === "chat" && <ChatContent />}
        {selectedTab === "image" && <Civit />}
      </div>
    </ChatModeProvider>
  );
};
