import React from "react";
import { Copy } from "lucide-react";
import { toast } from "sonner";
import { cn } from "~/lib/utils";

export default function CopyMessageButton({
  isHovered,
  message,
}: {
  isHovered: boolean;
  message: string;
}) {
  return (
    <button
      className="w-fit text-slate-400 hover:text-slate-800 p-2"
      onClick={() => {
        navigator.clipboard.writeText(message);
        toast.success("Copied to clipboard");
      }}
    >
      <Copy
        className={cn(
          "w-4 h-4  transition-all duration-300",
          isHovered ? "opacity-100" : "opacity-0"
        )}
      />
    </button>
  );
}
